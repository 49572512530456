import router from '../../router'
import { StoreOptions, Store } from 'vuex'
const createModule = <S>(store: StoreOptions<S>) => store as Store<S>
import i18n from '../../lang/index'
import { Locale } from 'vant'
import enUS from 'vant/es/locale/lang/en-US'
import zhCN from 'vant/es/locale/lang/zh-CN'

enum SizeType {
  large = 'Large',
  small = 'Small'
}
enum Routes {
  web = '/web',
  mobile = '/mobile'
}

function vantUseLocale(lang) {
  switch (lang) {
    case 'en':
      Locale.use('en-US', enUS)
      break
    case 'zh':
      Locale.use('zh-CN', zhCN)
      break
    default:
      break
  }
}
export default createModule({
  state: {
    showPageLoadScrollBar: true,
    locale: 'zh',
    size: SizeType.large,
    memberIdStr: ''
  },
  getters: {
    locale(state) {
      return state.locale
    }
  },
  mutations: {
    setShowPageLoadScrollBar(state, data: boolean) {
      state.showPageLoadScrollBar = data
    },
    setLocale(state, locale = 'en') {
      state.locale = locale
      localStorage.setItem('locale', locale)
      i18n.global.locale = locale
      vantUseLocale(locale)
    },
    setWidth(state, width) {
      const size = width > 992 ? SizeType.large : SizeType.small
      if (state.size !== size) {
        state.size = size
      } else {
        return true
      }
      if (state.size === SizeType.large) {
        router.replace(Routes.web)
      } else {
        router.replace(Routes.mobile)
      }
    },
    setMemberIdStr(state, memberIdStr) {
      if (memberIdStr) {
        state.memberIdStr = memberIdStr
      }
    }
  }
})
