import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import store from './store'
import i18n from './lang'
import 'element-plus/lib/theme-chalk/index.css'
import 'element-plus/lib/theme-chalk/display.css'
import './util/resize'
import Vant from 'vant'
import 'vant/lib/index.css';
import './style/font.css'
const app: ReturnType<typeof createApp> = createApp(App)
app
  .use(router)
  .use(ElementPlus)
  .use(Vant)
  .use(store)
  .use(i18n)
  .mount('#app')
