
import { defineComponent, onMounted, reactive, toRefs } from 'vue'
import { useStore } from 'vuex'
import router from '@/router/index'
export default defineComponent({
    setup() {
        const state = reactive({
            whiteList: ['/web', '/mobile']
        })
        const store = useStore()
        onMounted(() => {
            store.commit(
                'setLocale',
                localStorage.getItem('locale') || store.getters.locale
            )
            const width = window.innerWidth
            const path = router.currentRoute.value.path
            if (state.whiteList.includes(path)) {
                store.commit('setWidth', width)
            }
        })
        return {
            ...toRefs(state)
        }
    }
})
