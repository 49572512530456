import { RouteRecordRaw, createRouter, createWebHashHistory } from 'vue-router'
const web = () => import('../views/web/index.vue')
const mobile = () => import('../views/mobile/index.vue')
const register = () => import('../views/register/index.vue')
function getRedirect() {
  const width = window.innerWidth
  return width > 992 ? '/web' : '/mobile'
}
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: getRedirect()
  },
  {
    path: '/web',
    component: web,
    beforeEnter: (to, from, next) => {
      const width = window.innerWidth
      width > 992 ? next() : next('/mobile')
    }
  },
  {
    path: '/mobile',
    component: mobile,
    beforeEnter: (to, from, next) => {
      const width = window.innerWidth
      width < 992 ? next() : next('/web')
    }
  },
  {
    path: '/register',
    component: register
  }
]
const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
