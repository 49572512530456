export default {
  introduce: '介绍',
  introductionTitle: '新中高尔夫球会概况',
  welcome: '欢迎来到新中高尔夫球会',
  introduction1:
    '作为新加坡政府最早批准的高尔夫球会之一，我们的宗旨是：切磋球艺，增进友谊，回馈社会。',
  introduction2: '我们是一个成长中的球会，成员来自不同国家和专业背景',
  introduction3:
    '球会中热情的高尔夫球好手，可以为您提供各种指导，或者接受您的挑战:)',
  attentionClub: '关注球会',
  weChat: '微信公众号 ：“ SCGOLFCLUB ”',
  facebbok: 'Facebbok账号 ：“ Scgolf Singapore ”',
  memberBenefits: '新中高尔夫球会会员福利',
  benefits1: '1. 一件 SC 高尔夫球会制服',
  benefits2: '2. 优惠的果岭费',
  benefits3: '3. 优惠的差点维护费',
  benefits4: '4. 高尔夫比赛 （本地和海外）',
  benefits5: '5. 资深会员的球技指导',
  benefits6: '6. 球会组织的社交活动',
  benefits7: '7. 还有更多！',
  benefits8: '会员费：每年$ 120',
  clubProfile: '球会概况',
  applyForMembership1: '申请会员',
  applyForMembership2: '申请成为会员',
  contactUs: '联系我们',
  back: '返回',
  login: '登录',
  register: '注册',
  pwdWrong: '密码或用户名错误',
  forgetPassword: '忘记密码?',
  signUp: '注册',
  alreadyAccount: '已有账号? ',
  sendOtp: '发送验证码',
  reSend1: '',
  reSend2: ' 后重发',
  resetPassword: '重置密码',
  home: '首页',
  committee: '理事会',
  events: '活动',
  profile: '我的',
  errorMemberId: '用户ID有误',
  errorEmail: '邮件地址有误',
  errorMobileNum: '请输入正确的手机号',
  errorHandicapNumber: '不确定请输入25',
  errorPassword1: '密码强度低',
  errorPassword2: '确认密码不一致',
  errorOtp: '验证码错误',
  errorInformation: '请输入完整的登录信息',
  errorNickname: '请输入昵称',
  errorGivenName: '请输入名',
  errorSurname: '请输入姓',
  errorHandicapNum: '请输入号码',
  errorGender: '请选择性别',
  errorBirth: '请选择生日',
  errorShirtSize: '请选择T恤尺寸',
  errorNationality: '请输入国籍',
  errorBusinessType: '请输入行业类型',
  errorEcPerson: '请输入紧急联系人',
  errorEcNum: '请输入紧急联系人号码',
  requestFailed: '稍后再试',
  pEmail: '邮箱地址',
  pMemberId: '用户ID',
  pMobileNum: '手机号',
  pPassword: '密码',
  pNickname: '昵称',
  pSurname: '姓',
  pGivenName: '名',
  pHandicapNo: '差点',
  pConfirmPassword: '确认密码',
  pOtpCode: '验证码',
  pGender: '性别',
  pBirth: '生日',
  pEcPerson: '紧急联系人',
  pEcNum: '紧急联系人号码',
  pShirtSize: 'T恤',
  pNationality: '国籍',
  pBusinessType: '行业类型',
  pPhoto: '头像',
  male: '男',
  female: '女',
  reSend: '重新发送 ',
  emailAddress: 'SCGolfclub.sg@gmail.com',
  saveInfomation: '保存登录信息',
  confirm: '确认',
  cancel: '取消',
  btnUpload: '上传',
}
