export default {
  introduce: 'Introduction',
  introductionTitle: 'Overview of SC Golf Club',
  welcome: 'Welcome to SC Golf Club',
  introduction1:
    'As one of the earliest golf associations approved by the Singapore government, our aim is: to compete with each other, enhance friendship, and give back to the society.',
  introduction2:
    'We are a growing association with members from different countries and professional backgrounds',
  introduction3:
    'The enthusiastic golfers in SC Golf Club can provide you with various guidance or accept your challenge :)',
  attentionClub: 'Follow SC Golf Club',
  weChat: 'WeChat public account "SCGOLFCLUB"',
  facebbok: 'Facebook account "Scgolf Singapore"',
  memberBenefits: 'Member Benefits',
  benefits1: '1. One SC Golf Club uniform ',
  benefits2: '2. Preferential green fees ',
  benefits3: '3. Preferential fee for handicap index maintenance ',
  benefits4: '4. Golf tournaments (local and overseas) ',
  benefits5: '5. Friendly advice from experienced golfers ',
  benefits6: '6. Social activities organized by SC Golf Club ',
  benefits7: '7. And more! ',
  benefits8: 'Membership fee: $120 per year',
  clubProfile: 'Club Overview',
  applyForMembership1: 'Membership Application',
  applyForMembership2: 'Membership Application',
  contactUs: 'Contact Us',
  back: 'Back',
  login: 'Login',
  register: 'Register',
  pwdWrong: 'Incorrect password or user name',
  forgetPassword: 'Forget Password?',
  signUp: 'Sign Up',
  alreadyAccount: 'Already have an account? ',
  sendOtp: 'Send OTP',
  reSend1: 'Re-send in ',
  reSend2: '',
  resetPassword: 'Reset Password',
  home: 'Home',
  committee: 'Committee',
  events: 'Events',
  profile: 'Profile',
  errorMemberId: 'Please enter the correct Member Id.',
  errorEmail: 'Please enter the correct email.',
  errorMobileNum: 'Please enter the correct mobile num.',
  errorHandicapNumber: 'Put 25 if unsure.',
  errorPassword1: 'The password is too short',
  errorPassword2: 'The confirm password is inconsistent',
  errorOtp: 'Please enter the correct OTP',
  errorInformation: 'Please enter the login information completely',
  errorNickname: 'Please enter Nickname',
  errorGivenName: 'Please enter GivenName',
  errorSurname: 'Please enter Surname',
  errorHandicapNum: 'Please enter Handicap Index.',
  errorGender: 'Please Choose Gender',
  errorBirth: 'Please Choose Birthdate',
  errorShirtSize: 'Please Choose Shirt Size',
  errorNationality: 'Please enter Nationality',
  errorBusinessType: 'Please enter BusinessType',
  errorEcPerson: 'Please enter Emergency Contact Person',
  errorEcNum: 'Please enter Emergency Contact Number',
  requestFailed: 'Request Failed',
  pMemberId: 'Member Id',
  pEmail: 'Email',
  pMobileNum: 'Mobile Number',
  pPassword: 'Password',
  pNickname: 'Nickname',
  pSurname: 'Surname',
  pGivenName: 'Given Name',
  pHandicapNo: 'Handicap Index.',
  pConfirmPassword: 'Confirm Password',
  pOtpCode: 'OTP Code',
  pGender: 'Gender',
  pBirth: 'Birthdate',
  pEcPerson: 'Emergency Contact Person',
  pEcNum: 'Emergency Contact Number',
  pShirtSize: 'Shirt Size',
  pNationality: 'Nationality',
  pBusinessType: 'Type of Business',
  pPhoto: 'Upload photo of yourself',
  male: 'Male',
  female: 'Female',
  reSend: 'Re-send in ',
  emailAddress: 'SCGolfclub.sg@gmail.com',
  saveInfomation: 'save login information',
  confirm: 'Confirm',
  cancel: 'Cancel',
  btnUpload: 'Upload',
}
