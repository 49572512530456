import { createStore } from 'vuex'
import app from './modules/app'
import getters from './getters'

const store = createStore({
  getters,
  modules: {
    app,
  },
})
declare module 'vuex' {
  type StoreStateType = typeof store.state
  type ModulesType = {
    app: typeof app.state
  }
  export function useStore<S = StoreStateType & ModulesType>(): Store<S>
}

export default store
